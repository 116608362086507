<template>
    <div class="text-2 col-lg-12 p-0">
        <h4 class="text-center">Privacy Policy</h4>
        <p class="mt-3"> Last updated February 21, 2024</p>

        <p> Fovea tech private limited built the VetConnect app for<b> educational purpose only</b>. The doses of veterinary drugs and other data shown in the app are
            provided by best of
            our
            knowledge. This SERVICE is provided by Fovea tech private limited is intended for use as is.</p>

        <p> This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service.</p>

        <p>If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that I collect is used for
            providing and improving the Service. I will not use or share your information with anyone except as described in this Privacy Policy.</p>

        <p> The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at VetConnect unless otherwise defined in this Privacy
            Policy.</p>

        <h5> Terms and conditions</h5>
        <ul class="ml-5">
            <li>Only Veterinary doctors or Veterinary students are allowed to use this app.</li>
            <li>Vetconnect App or Fovea tech private limited is not responsible for any misshapen (injuries or death) while treating animals with the data provided by VetConnect
                App.
            </li>
            <li>The drug doses seen in the brand section are provided by the company website, and neither Vetconnect App or Fovea tech private limited are not responsible for any
                mistake.
            </li>
        </ul>

        <h5 class="mt-6">Information Collection and Use</h5>
        <p> For a better experience, while using our Service, I may require you to provide us with certain personally identifiable information, including but not limited to Collect
            the
            contact numbers with the permission from authority. The information that I request will be retained on your device and is not collected by us in any way.</p>

        <p>Our App is uploading users' primary account information to our server which will be secured. The app does use third party services that may collect information used to
            identify you. Link to privacy policy of third party service providers used by the app</p>

        <a>Google Play Services</a>
        <a>Firebase Analytics</a>
        <a>Onesignal sdk</a>
        <h5 class="mt-3"> Log Data</h5>
        <p>I want to inform you that whenever you use our Service, in a case of an error in the app I collect data and information (through third party products) on your phone
            called
            Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the
            app
            when utilizing our Service, the time and date of your use of the Service, and other statistics.</p>

        <h5>Cookies</h5>
        <p>Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and
            are stored on your device's internal memory.</p>

        <p>This Service does not use these “cookies” explicitly. However, the app may use third party code and libraries that use “cookies” to collect information and improve their
            services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may
            not
            be able to use some portions of this Service.</p>

        <h5>Service Providers</h5>
        <p>We may employ third-party companies and individuals due to the following reasons:</p>

        <p> To facilitate our Service; To provide the Service on our behalf; To perform Service-related services; or To assist us in analyzing how our Service is used. We want to
            inform users of this Service that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf.
            However,
            they are obligated not to disclose or use the information for any other purpose.</p>

        <h5>Security</h5>
        <p> We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method
            of
            transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</p>

        <h5> Links to Our Website</h5>
        <p> Our app may contain links to our website, which is operated and controlled by us. We may provide these links to offer additional information, resources, or services
            related
            to the app's content</p>

        <h5> Links to Other Sites</h5>
        <p>This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by
            me
            except one. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content,
            privacy
            policies, or practices of any third-party sites or services.</p>

        <h5>Children’s Privacy</h5>
        <p>These Services do not address anyone under the age of 13. I do not knowingly collect personally identifiable information from children under 13. In the case we discover
            that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that
            your
            child has provided us with personal information, please contact us so that we will be able to do necessary actions.</p>

        <h5>Changes to This Privacy Policy</h5>
        <p>We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting
            the
            new Privacy Policy on this page. These changes are effective immediately after they are posted on this page.</p>

        <h5>Contact Us</h5>
        <p> If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us.<br>Our email id foveatechvet@gmail.com</p>
    </div>
</template>

<script>
export default {
    name: 'PrivacyPolicy'
};
</script>

<style scoped>

</style>
